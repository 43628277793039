<template>
  <router-view />
</template>
 <script setup name="app">
</script>
<style lang="scss">
@font-face {
  font-family: msyh;
  src: url("../src/assets/fontSize/msyh.ttc");
}
@font-face {
  font-family: msyhbd;
  src: url("../src/assets/fontSize/msyhbd.ttc");
}

@font-face {
  font-family: msyhl;
  src: url("../src/assets/fontSize/msyhl.ttc");
}

@font-face {
  font-family: Bold;
  src: url("../src/assets/fontSize/Bold.OTF");
}

@font-face {
  font-family: Extralight;
  src: url("../src/assets/fontSize/Extralight.OTF");
}

@font-face {
  font-family: Heavy;
  src: url("../src/assets/fontSize/Heavy.OTF");
}

@font-face {
  font-family: Light;
  src: url("../src/assets/fontSize/Light.OTF");
}

@font-face {
  font-family: Medium;
  src: url("../src/assets/fontSize/Medium.OTF");
}

@font-face {
  font-family: Normal;
  src: url("../src/assets/fontSize/Normal.OTF");
}

@font-face {
  font-family: Regular;
  src: url("../src/assets/fontSize/Regular.OTF");
}
* {
  // font-family: Heavy;
}

.activesText {
  background: -webkit-linear-gradient(left, #ef8a2c, #e53829);
  // background: linear-gradient(to right, #ef8a2c, #e53829);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  // background: linear-gradient(#ef8a2c, #e53829);
  background-clip: text;
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1200px !important;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 66.66% !important;
  }
}
img {
  transition: all 1s;
}
img:hover {
  /* 鼠标悬停时放大 */
  transform: scale(1.2);
}
.html img:hover {
  transform: scale(1);
}
/* @media (min-width: 992px) and (max-width: 1400px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 990px!important;
  }
} */
</style>
