import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'swiper/css';
import 'swiper/swiper-bundle.css';
// import 'animate.css';
import 'animate.css/animate.min.css' //引入

// initRem()
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import vue3SeamlessScroll from "vue3-seamless-scroll";


import VueAmazingUI from 'vue-amazing-ui'
import 'vue-amazing-ui/css'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css';
const app = createApp(App)
app.use(vue3SeamlessScroll)
app.use(VueAmazingUI)
app.use(ElementPlus)
app.use(store)
app.use(router)
app.mount('#app')

 