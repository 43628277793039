import { createRouter, createWebHistory ,createWebHashHistory} from 'vue-router'
// import HomeView from '../view/home'
const routes = [
  {
    path: '/',
    component: () => import('@/view/index/index.vue'),
    redirect: '/home',
    // children: [
    //   {
    //     path: '/home',
    //     name: '首页',
    //     component: () => import('@/view/joinUsView/index.vue'),
    //   },
    //   {
    //     path: '/home/programme',
    //     name: '人力资源服务方案',
    //     component: () => import('@/view/programmeView/index.vue'),
    //   },
    //   {
    //     path: '/home/case',
    //     name: '服务案例',
    //     component: () => import('@/view/caseView/index.vue'),
    //   },
    //   {
    //     path: '/home/industry',
    //     name: '行业资讯',
    //     component: () => import('@/view/industryView/index.vue'),
    //   },

    //   {
    //     path: '/home/about',
    //     name: '关于才燊',
    //     component: () => import('@/view/aboutView/index.vue'),
    //   },
    //   {
    //     path: '/home/contact',
    //     name: '联系我们',
    //     component: () => import('@/view/contactView/index.vue'),
    //   },  
    //   {
    //     path: '/home/joinUs',
    //     name: 'joinUs',
    //     component: () => import('@/view/home/index.vue'),
    //   },  
    // ]


    children: [
      {
        path: '/home',
        name: '首页',
        component: () => import('@/view/home/index.vue'),
      },
      {
        path: '/home/programme',
        name: '产品解决方案',
        component: () => import('@/view/programmeView/index.vue'),
      },
      {
        path: '/home/case',
        name: '服务案例',
        component: () => import('@/view/caseView/index.vue'),
      },
      {
        path: '/home/industry',
        name: '行业资讯',
        component: () => import('@/view/industryView/index.vue'),
      },

      {
        path: '/home/about',
        name: '关于才燊',
        component: () => import('@/view/aboutView/index.vue'),
      },
      {
        path: '/home/contact',
        name: '联系我们',
        component: () => import('@/view/contactView/index.vue'),
      },  
      {
        path: '/home/joinUs',
        name: 'joinUs',
        component: () => import('@/view/joinUsView/index.vue'),
      },  
    ]
  }
]

const router = createRouter({
  // mode: 'hash',
  history: createWebHashHistory(),
  // history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(function() {
  window.scrollTo(0, 0)
})

export default router
